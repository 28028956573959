<template>
  <b-container class="text-center">
    <div class="personal-image text-center" >

      <label class="label-image">
        <input ref="fileInput" type="file" accept="image/*" @change="uploadFile(file)">
        <figure class="personal-figure">
          <img :src="avatarUrl" class="personal-avatar" alt="avatar">
          <figcaption class="personal-figcaption">
            <img
              :src="camera"
              alt="camera image"
            >
          </figcaption>
        </figure>
        <b-button
          class="btn-icon"
          variant="dark"
          style="position: absolute;right: 115px; bottom:15px; border-radius: 100%;"
          @click="removeImage"
        >
          <feather-icon
            icon="TrashIcon"
            style="font-size: 1.7em"
          />
        </b-button>
      </label>

    </div>
  </b-container>
</template>

<script>
import camera from '@/assets/images/icons/camera-white.png'
import defaultAvatar from '@/assets/images/icons/blank-Avatar.png'

export default {
  name: 'SAvatarUpload',
  props: {
    url: {
      type: [String, File],
      required: false,
      default: defaultAvatar,
    },
  },
  data() {
    return {
      avatarUrl: defaultAvatar,
      file: null,
      camera,
    }
  },
  watch: {
    url(value) {
      if (value) {
        this.avatarUrl = value
      }
    },
  },
  mounted() {
    this.avatarUrl = this.url
  },
  methods: {
    uploadFile() {
      const input = this.$refs.fileInput
      const file = input.files
      if (file && file[0]) {
        const reader = new FileReader()
        reader.onload = e => {
          this.avatarUrl = e.target.result
        }
        reader.readAsDataURL(file[0])
        this.$emit('input', file[0])
      }
    },
    removeImage() {
      this.avatarUrl = defaultAvatar
      this.$emit('input', null)
      this.$emit('onDelete')
    },
  },
}
</script>

<style scoped>
.personal-image {
    text-align: center;
}
.personal-image input[type="file"] {
    display: none;
}
.personal-figure {
    position: relative;
    width: 120px;
    height: 120px;
}
.personal-avatar {
    cursor: pointer;
    width: 120px;
    height: 120px;
    box-sizing: border-box;
    border-radius: 100%;
    border: 2px solid transparent;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
    transition: all ease-in-out .3s;
}
.personal-avatar:hover {
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
}
.personal-figcaption {
    cursor: pointer;
    position: absolute;
    top: 0px;
    width: inherit;
    height: inherit;
    border-radius: 100%;
    opacity: 0;
    background-color: rgba(0, 0, 0, 0);
    transition: all ease-in-out .3s;
}
.personal-figcaption:hover {
    opacity: 1;
    background-color: rgba(0, 0, 0, .5);
}
.personal-figcaption > img {
    margin-top: 32.5px;
    width: 50px;
    height: 50px;
}
.label-image {
    padding: 0px;
    margin-bottom: 0px!important;
    height: 120px;
}
</style>
