<template>
  <!--  http://www.boreodesk.com.br/Layout/img/usuarios/100001.jpg-->
  <b-media vertical-align="center">
    <template #aside>
      <b-avatar
        class="float-left mr-1"
        style="position: relative;"
        :src="user.avatar"
      />
      <div>
        {{ user.name }}
        <br>
        <span class="small text-muted">{{ user.email }}</span>
      </div>

    </template>

  </b-media>
</template>

<script>
import { BAvatar, BMedia } from 'bootstrap-vue'

export default {
  name: 'UserCard',
  components: {
    BMedia, BAvatar,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
}

</script>

<style scoped>

</style>
