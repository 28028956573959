<template>
  <b-sidebar
    id="add-new-user-sidebar"
    ref="sideBar"
    :visible="isActive"
    backdrop
    :no-close-on-esc="true"
    :no-close-on-backdrop="true"
    bg-variant="white"
    title="asdasd"
    right
    shadow
    sidebar-class="sidebar-lg"
    header-class="content-sidebar-header"
    footer-class="content-sidebar-footer"
    @change="(val) => $emit('update:is-active', val)"
  >
    <template #header="{hide}" >
      <div class="d-flex justify-content-between align-items-center w-100 p-1" >
        <h5 class="mb-0">
          Cadastro de Usuários
        </h5>
        <feather-icon
          class="ml-1 cursor-pointer float-right"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>
    </template>
    <template #footer="{hide}" >
      <div class="p-1" >
        <b-row>
          <b-col
            sm="12"
            md="12"
            class="text-right"
          >

            <!-- Form Actions -->
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              type="button"
              class="mr-2"
              @click="onSubmit"
            >

              <feather-icon
                icon="SaveIcon"
                class="mr-50"
              />
              Salvar
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-danger"
              type="button"
              @click="onCancel"
            >

              <font-awesome-icon
                prefix="far"
                :icon="['far', 'times-circle']"
                class="mr-50"
              />
              Cancelar
            </b-button>
          </b-col>
        </b-row>
      </div>
    </template>

    <template #default="{ hide }">
      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <b-row>
            <b-col
              sm="12"
              md="12"
              class="text-center"
            >
              <s-avatar-upload v-model="model.avatar" :url="model.avatar"/>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              sm="12"
              md="12"
            >
              <i2-form-input
                v-model="model.name"
                rules="required"
                label="Nome"
                placeholder="informe o nome do usuário"
                name="name"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col
              sm="12"
              md="12"
            >
              <i2-form-input
                v-model="model.email"
                rules="required|email"
                label="E-mail (login)"
                placeholder="Login do usuário (email)"
                name="email"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group>
                <label>Usuário ativo</label>
                <b-form-checkbox
                  v-model="model.is_active"
                  checked="true"
                  name="is_active"
                  switch
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              sm="12"
              md="12"
            >
              <i2-form-vue-select
                v-model="model.type"
                name="type"
                rules="required"
                label="Tipo do usuário"
                :options="usersTypes"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col
              sm="12"
              md="12"
            >
              <i2-form-password
                v-model="model.password"
                label="Senha"
                name="password"
                :rules="passwordValidator"
              />
            </b-col>
          </b-row>
          <i2-form-password
            v-model="model.confirm_password"
            label="Informe novamente a Senha"
            name="password_confirmation"
            :rules="confirmPasswordValidator"
          />
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>
<script>
import { ValidationObserver } from 'vee-validate'
import { alphaNum, email, required } from '@validations'
import Ripple from 'vue-ripple-directive'
import userType from '@/variables/userTypes'
import I2FormInput from '@/components/i2/forms/elements/I2FormInput.vue'
import I2FormPassword from '@/components/i2/forms/elements/I2FormPassword.vue'
import I2FormVueSelect from '@/components/i2/forms/elements/I2FormVueSelect.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { mapActions } from 'vuex'
import SAvatarUpload from '@/components/i2/forms/elements/SAvatarUpload.vue'

export default {
  components: {
    SAvatarUpload,
    I2FormVueSelect,
    I2FormInput,
    I2FormPassword,
    // Form Validation
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
    isActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      active: false,
      usersTypes: userType,
      passwordValidator: 'password|min:6',
      lastCompany: '',
      confirmPasswordValidator: '',
      currentUser: null,
      model: {
        name: '',
        uuid: null,
        is_active: true,
        avatar: '',
        email: '',
        type: {
          value: null,
          text: 'Selecione',
        },
        password: '',
        confirm_password: '',
      },
      originalData: {},
      blankUser: {
        name: '',
        uuid: null,
        is_active: true,
        email: '',
        type: {
          value: null,
          text: 'Selecione',
        },
        password: '',
        confirm_password: '',
      },
    }
  },
  watch: {
    isAddNewUserSidebarActive(data) {
      this.active = data
    },
    user(data) {
      if (data.uuid === undefined) {
        this.model = { ...this.blankUser }
        this.passwordValidator = 'required|password|min:6'
        this.confirmPasswordValidator = 'required|confirmed:password|password'
        this.$refs.refFormObserver.reset()
        return
      }
      this.model = { ...data }

      this.model.type = this.usersTypes.find(type => type.value === data.type)
      if (data.uuid !== undefined && data.uuid !== '') {
        this.passwordValidator = 'password|min:6'
        this.confirmPasswordValidator = 'confirmed:password'
      }
      if (!data.uuid) {
        this.model = { ...this.blankUser }
        this.passwordValidator = 'required|password|min:6'
        this.confirmPasswordValidator = 'required|confirmed:password'
        this.$refs.refFormObserver.reset()
      }
    },
    // eslint-disable-next-line func-names
  },
  async created() {
    await this.init()
  },
  methods: {
    ...mapActions('auth', ['me']),
    async init() {
      const userTypes = []
      const user = await this.me()
      for (const type of userType) {
        if (user.role !== 'super_admin' && type.value === 'super_admin') {
          continue
        }
        userTypes.push(type)
      }
      this.usersTypes = userTypes

      if (!this.user.uuid) {
        this.model = { ...this.blankUser }
        this.passwordValidator = 'required|password|min:6'
        this.confirmPasswordValidator = 'required|confirmed:password|min:6'
        this.$refs.refFormObserver.reset()
      }
    },
    onSubmit() {
      this.$refs.refFormObserver.validate().then(async success => {
        if (success) {
          let response = {}
          const data = { ...this.model }
          data.role = this.model.type.value
          const formData = new FormData()
          formData.append('role', this.model.type.value)
          formData.append('email', this.model.email)
          formData.append('name', this.model.name)
          formData.append('is_active', this.model.is_active)
          if (this.model.password != null) {
            formData.append('password', this.model.password)
            formData.append('password_confirmation', this.model.confirm_password)
          }

          if (this.model.avatar !== null) {
            formData.append('avatar', this.model.avatar)
          }
          if (this.model.uuid) {
            formData.append('_method', 'put')
            response = await this.$http.post(`users/${this.model.uuid}`, formData)
          } else {
            response = await this.$http.post('users', formData)
          }
          if (response.error) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: response.error_message,
                icon: 'EditIcon',
                variant: 'danger',
              },
            })
            this.$refs.refFormObserver.setErrors(response.errors || [])
            return
          }
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Usuário salvo com sucesso.',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
          this.$emit('onUserSave', response)
          this.model = { ...this.blankUser }
          this.$refs.refFormObserver.reset()
        }
      })
    },
    onCancel() {
      this.$emit('onCancel', true)
      this.model = { ...this.blankUser }
      this.$refs.refFormObserver.reset()
    },
  },
}
</script>
