<template>
  <div>
    <b-card>
      <b-row>
        <b-col>
          <h2>Usuários do Sistema</h2>
        </b-col>
        <b-col
          md="6"
          class="text-right"
        >
          <b-button
            variant="outline-warning"
            class="mr-1"
            @click="onCreateToken"
          >
            <FeatherIcon icon="Share2Icon" />
            &nbsp;<span class="text-nowrap">Criar link de cadastro</span>
          </b-button>

          <b-button
            variant="primary"
            @click="newUser"
          >
            <FeatherIcon icon="PlusIcon" />
            &nbsp;<span class="text-nowrap">Adicionar</span>
          </b-button>
        </b-col>
      </b-row>
    </b-card>
    <b-card-code>
      <user-new
        :user="currentUser"
        :is-active.sync="isActivated"
        @onUserSave="onUserSave()"
        @onCancel="onCancelEdit()"
      />

      <Grid
        :fields="fields"
        :items="items"
        edit-button-label="edit"
        new-button-label="new"
        :show-search="false"
        @onChangePage="getUsers"
      >
        <template v-slot:search>
          <b-row class="p-0 pl-1">
            <b-col
              md="10"
              class="p-0 pr-1"
            >
              <b-form-input
                v-model="search.search"
                class="filter-input"
                placeholder="Pesquisar por Nome"
                name="nome"
              />
            </b-col>
            <b-col
              sm="12"
              md="2"
            >
              <b-button
                variant="secondary"
                type="button"
                @click="getUsers"
              >
                <FeatherIcon icon="SearchIcon" />
                <span
                  class="text-nowrap"
                />
              </b-button>
            </b-col>
          </b-row>
        </template>
        <template #cell(name)="data">
          <user-card :user="data.item"/>
        </template>
        <template #cell(type)="data">
          {{ userType.find(type => type.value === data.item.type).text }}
        </template>
        <template #cell(email)="data">
          {{ data.item.email }}<br>
          <b-badge
            v-if="!data.item.is_registered"
            class="d-block"
            variant="warning"
          >
            Não Registrado
          </b-badge>

        </template>
        <template #cell(is_active)="data">
          <b-badge
            v-if="data.item.is_active"
            class="d-block"
            variant="success"
          >
            Ativo
          </b-badge>
          <b-badge
            v-if="!data.item.is_active"
            class="d-block"
            variant="danger"
          >
            Inativo
          </b-badge>
        </template>
        <template
          #cell(action)="data"
          style="width: 20%"
        >
          <div class="text-center">
            <i2-icon-button
              icon="EditIcon"
              variant="outline-primary"
              class="mr-1"
              @click="setCurrentUser(data.item)"
            />
            <i2-icon-button
              fa-icon="fa-trash"
              variant="outline-danger"
              class="mr-1"
              @click="deleteUser(data.item)"
            />
            <i2-icon-button
              v-if="!data.item.is_registered"
              v-b-tooltip.hover.noninteractive="'registrar o usuário'"
              fa-icon="fas fa-user-check"
              variant="outline-success"
              class="mr-1"
              title="registrar"
              @click="activateUser(data.item)"
            />
          </div>
        </template>
      </Grid>
    </b-card-code>
    <b-modal
      v-model="showModalToken"
      title="Gerar link para Cadastro de Usuários"
      ok-title="Salvar"
      cancel-title="Fechar"
      no-close-on-backdrop
      @ok="onRequestToken"
    >
      <validation-observer
        ref="refFormTokenObserver"
      >
        <b-card-text>
          Criar um link para auto-cadastro de usuário para acesso a plataforma. Se for informado um e-mail, este receberá uma mensagem contendo o link para cadastro.
        </b-card-text>
        <b-card-text>
          <h6>{{ linkToken }}</h6>
        </b-card-text>
        <i2-form-input
          v-model="tokenEmail"
          required
          type="email"
          label="Email"
          rules="email"
        />
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import UserNew from '@/views/users/UserNew.vue'
import Grid from '@/components/i2/grid/Grid.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import userType from '@/variables/userTypes'
import UserCard from '@/components/app/User/UserCard.vue'
import { VBTooltip } from 'bootstrap-vue'

export default {
  components: {
    UserCard,
    Grid,
    BCardCode,
    UserNew,
  },
  directives: { 'b-tooltip': VBTooltip },
  data() {
    return {
      search: {
        search: '',
        role: '',
      },
      fields: [
        {
          key: 'name',
          label: 'Nome',
          class: 'w-20',
        },
        {
          key: 'email',
          label: 'e-mail',
          class: 'w-20',
        },

        {
          key: 'type',
          label: 'Perfil',
          class: 'w-20',
        },
        {
          key: 'is_active',
          label: 'Ativo',
          class: 'w-10',
        },
        {
          key: 'action',
          label: 'Ações',
          class: 'w-20',
        },
      ],
      items: [],
      currentUser: {},
      tokenEmail: '',
      isActivated: false,
      userType,
      showModalToken: false,
      linkToken: '',
    }
  },
  watch: {
    isActivated(val) {
      if (!val) {
        this.currentUser = {}
      }
    },
  },
  mounted() {
    this.getUsers()
  },
  methods: {
    async getUsers(page) {
      this.items = await this.$search.get(
        'users',
        page,
        this.search,
        '',
      )
    },
    setCurrentUser(user) {
      this.currentUser = { ...user }
      this.isActivated = true
    },
    newUser() {
      this.currentUser = {}
      this.isActivated = true
    },
    onUserSave() {
      this.currentUser = {}
      this.isActivated = false
      this.getUsers()
    },
    deleteUser(user) {
      this.$bvModal.msgBoxConfirm('Por favor, confirme a exclusão do usuário.', {
        title: 'Confirm exclusão?',
        size: 'sm',
        okVariant: 'primary',
        okTitle: 'Sim',
        cancelTitle: 'Não',
        cancelVariant: 'outline-secondary',
        hideHeaderClose: false,
        centered: true,
      })
        .then(async value => {
          if (value) {
            const response = await this.$http.delete(`users/${user.uuid}`)
            if (response.error) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: response.error_message,
                  icon: 'EditIcon',
                  variant: 'danger',
                },
              })
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: `O usuário ${user.name} foi removido com sucesso.`,
                  icon: 'EditIcon',
                  variant: 'success',
                },
              })
              this.getUsers()
            }
          }
          return true
        })
    },
    onCancelEdit() {
      this.isActivated = false
    },
    async activateUser(user) {
      const accepted = await this.$bvModal.msgBoxConfirm('Por favor, confirme o registro do usuário.', {
        title: `Confirmar o registro do usuario ${user.name}?`,
        size: 'sm',
        okVariant: 'primary',
        okTitle: 'Sim',
        cancelTitle: 'Não',
        cancelVariant: 'outline-secondary',
        hideHeaderClose: false,
        centered: true,
      })
      if (!accepted) {
        return
      }
      const response = await this.$http.post(`users/${user.uuid}/register`)
      if (response.error) {
        this.$notify.error(response.error_message)
        return
      }
      this.$notify.success(`Usuário ${user.name} registrado com sucesso.`)
      this.getUsers()
    },
    onCreateToken() {
      this.showModalToken = true
      this.tokenEmail = ''
      this.linkToken = ''
    },

    async onRequestToken(event) {
      event.preventDefault()
      const data = { email: this.tokenEmail }
      const response = await this.$http.post('/users/token/request', data)
      if (response.error) {
        this.$notify.error(response.error_message)
        return
      }
      this.$notify.success('Token gerado com sucesso')
      this.linkToken = response.link
    },
  },
}
</script>
<style>

</style>
